import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AuthContext from '../../components/AuthContext/AuthContext';
import CertModal from '../../components/CertModal/CertModal';

function ListenerCourses(props) {
    let { courses, issuingStatus, setIssuingStatus } = props;
    const [listenerCourses, setListenerCourses] = useState([]);
    const { role, userData } = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState({});

    const handleOpenModal = (course) => {
        setSelectedCourse(course);
        setShowModal(true);
    };
    const handleCloseModal = (changeTranscript = null) => {
        setSelectedCourse(null);
        setShowModal(false);
        if (changeTranscript)
            changeTranscript(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let tmpArray = [];
                courses.map((item) => {
                    let tmpObject = {};
                    tmpObject.id = item.id;
                    tmpObject.fullname = item.courseInfo.fullname;
                    tmpObject.shortname = item.courseInfo.shortname;
                    tmpObject.timestarted = item.timestarted;
                    tmpObject.timecompleted = item.timecompleted;
                    tmpObject.enrol_timestart = item.courseInfo.enrol_timestart;
                    tmpObject.enrol_timeend = item.courseInfo.enrol_timeend;
                    tmpObject.course = item.course;
                    tmpObject.userid = item.userid;
                    tmpObject.firstname = item.userInfo.firstname;
                    tmpObject.lastname = item.userInfo.lastname;
                    tmpObject.middlename = item.userInfo.middlename;
                    tmpObject.email = item.userInfo.email;
                    item.courseInfo.customFields.map((item) => {
                        switch (item.shortname) {
                            case "cycle_hours":
                                tmpObject.cyclehours = item.value;
                                break;
                            case "type":
                                tmpObject.type = item.value;
                                break;
                            case "course_specialty":
                                tmpObject.specialty = item.value;
                                break;
                            case "cyclekz":
                                tmpObject.cyclekz = item.value;
                                break;
                            case "credits":
                                tmpObject.credits = item.value;
                                break;
                        }
                    });
                    item.userInfo.customFields.map((item) => {
                        switch (item.fieldname) {
                            case "iin":
                                tmpObject.iin = item.fieldvalue;
                                break;
                            case "phoneRMA":
                                tmpObject.phoneRma = item.fieldvalue;
                        }
                    });

                    tmpArray.push(tmpObject);
                });

                setListenerCourses(tmpArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [courses]);

    var rowNum = 1;
    return (
        <div className="px-2 pt-3">
            <div className="border rounded px-1">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Название</th>
                            <th scope="col">Тип</th>
                            <th scope="col">Специальность</th>
                            <th scope="col">Часы</th>
                            <th scope="col">Филиал</th>
                            <th scope="col">Управление</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listenerCourses.map((item) => (
                            <tr key={item.id}>
                                <th scope="row">{rowNum++}</th>
                                <td>{item.fullname}</td>
                                <td>{item.type}</td>
                                <td>{item.specialty}</td>
                                <td>{item.cyclehours}</td>
                                <td>{localStorage.branchName}</td>
                                <td>
                                    <button className="btn btn-primary" onClick={() => handleOpenModal(item)}>Выпустить</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <CertModal show={showModal} data={selectedCourse} issuingStatus={issuingStatus} setIssuingStatus={setIssuingStatus} onClose={handleCloseModal} />
            </div>
        </div>
    );
}

export default ListenerCourses;