import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Courses from "./components/Courses/Courses";
import Listeners from "./components/Listeners/Listeners";
import axios from "axios";
import Login from "./components/Login/Login";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import ListenerDetails from "./components/ListenerDetails/ListenerDetails";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthContext from "./components/AuthContext/AuthContext";

function App({ navigate }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({
    jwttoken: "",
    platformToken: "",
    platformURL: "",
  });
  const [authCheckingCompleted, setAuthCheckingCompleted] = useState(false);
  const [platformData, setPlatformData] = useState([]);
  // const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const [role, setRole] = useState("");

  const login = (role, data) => {
    localStorage.setItem("token", data.jwttoken);
    localStorage.setItem("role", role);
    localStorage.setItem("branchName", data.BranchName);
    localStorage.setItem("branchId", data.BranchId);
    localStorage.setItem("prefix", data.prefix);
    setUserData(data);
    setIsLoggedIn(true);
    setRole(role);
    setAuthCheckingCompleted(true);
  };
  const logout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    setRole("");
    setAuthCheckingCompleted(true);
    setUserData({ jwttoken: "", platformToken: "", platformURL: "" });
    setPlatformData([]);
    navigate("/login");
  };
  const fetchPlatform = async () => {
    try {

      const jwttoken = localStorage.getItem("token");
      const role = localStorage.getItem("role");
      
      if (!role || !jwttoken) {
        alert("Перезайдите пожалуйста.");
        return;
      }

      const headers = {
        Authorization: `Bearer ${jwttoken}`,
        "Content-Type": "application/json",
      };

      const dataUrl =
        role == "admin"
          ? "https://rmacerts.ripov-edu.kz/api/alldata"
          : "https://rmacerts.ripov-edu.kz/api/data";

      const response = await axios.post(
        dataUrl,
        { username: role },
        { headers }
      );

      if (response?.data?.exception) {
        throw new Error(response.data);
      }

      if (
        (response.status >= 200) & (response.status < 300) &&
        JSON.stringify(platformData) !== JSON.stringify(response.data)
      ) {
        if (role == "admin") {
          var parsedData = parseAllData(response.data);
          setPlatformData(parsedData);
        } else {
          setPlatformData(response.data);
        }
      }

    } catch (error) {

      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        alert(error.response.data.error);
        logout();
      } else {
        alert("Попробуйте перезайти.");
        logout();
      }

      throw new Error(error);

    }
  };

  const parseAllData = (data) => {

    return data.flat();

  };

  const fetchCerts = async (userid) => {

    try {

      const branchid = userData.BranchId;
      const jwttoken = localStorage.getItem("token");

      if (!jwttoken) {
        console.error("JWT TOKEN IS NOT AVAILABLE");
        return;
      }

      const headers = {
        Authorization: `Bearer ${jwttoken}`,
        "Content-Type": "application/json",
      };

      const role = localStorage.getItem("role");

      if (!role) {
        console.error("RELOGIN please");
        return;
      }

      const response = await axios.post(
        "https://rmacerts.ripov-edu.kz/api/getListenerCerts",
        { userId: userid, branchId: branchid },
        { headers }
      );

      if (response?.data?.exception) {
        throw new Error(response.data);
      }

      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }

    } catch (error) {

      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        alert(error.response.data.error);
        logout();
      } else {
        alert("Попробуйте перезайти.");
        logout();
      }

      throw new Error(error);

    }
  };

  useEffect(() => {

    if (!isLoggedIn) return;

    const IDLE_TIMEOUT = 30 * 60 * 1000;
    let idleTimer;

    const resetIdleTimer = () => {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(() => {
        logout();
        alert("Вы вышли из системы за бездействие.");
      }, IDLE_TIMEOUT);
    };

    document.addEventListener("mousemove", resetIdleTimer, false);
    document.addEventListener("keydown", resetIdleTimer, false);
    document.addEventListener("click", resetIdleTimer, false);

    resetIdleTimer();

    return () => {
      document.removeEventListener("mousemove", resetIdleTimer, false);
      document.removeEventListener("keydown", resetIdleTimer, false);
      document.removeEventListener("click", resetIdleTimer, false);
      clearTimeout(idleTimer);
    };

  }, [isLoggedIn]);

  useEffect(() => {

    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    if (token && role) {
      setIsLoggedIn(true);
      setRole(role);
    }

    setAuthCheckingCompleted(true);
    
  }, []);

  return (
    <div className="container-fluid App">
      {/* <Router> */}
      <AuthContext.Provider
        value={{
          isLoggedIn,
          login,
          logout,
          role,
          userData,
          setPlatformData,
          platformData,
          fetchPlatform,
          fetchCerts,
        }}
      >
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute
                isAuthenticated={isLoggedIn}
                authCompleted={authCheckingCompleted}
                key={isLoggedIn ? "authenticated" : "unauthenticated"}
              />
            }
          >
            <Route path="/" element={<Home key={Date.now()} />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route
            path="/details/listeners/:userid"
            element={<ListenerDetails />}
          />
          <Route
            path="/details/courses/:userid"
            element={<ListenerDetails />}
          />
          <Route path="/details/courses" element={<Courses />} />
          <Route path="/details/listeners" element={<Listeners />} />
          <Route path="*" element={<div>Страница не найдена</div>} />
        </Routes>
      </AuthContext.Provider>
      {/* </Router> */}
    </div>
  );
}

export default App;
